.profile-upcoming-match {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  /* border: 1px solid red; */ /* TODO - remove debugging */
  border-radius: 5px;
  background-color: #12151a;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-radius: 10px;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-bottom: 1vh;
}

.profile-upcoming-match-image {
  width: 25%;
  padding-right: 1vw; 
}

.profile-upcoming-match-info{
  width: 75%;
  /* border: 1px solid blue;*/ /* TODO - Remove Debugging */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-upcoming-match-info-details{
  width: 50%;
  /* border: 1px solid yellow; */ /* TODO - Remove Debugging */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-upcoming-match-info-details-game-type{
  color: #e2e4e8 !important;
  font-weight: bold;
  padding-bottom: 1vh;
}

.profile-upcoming-match-info-date-time{
  width: 50%;
  /* border: 1px solid yellow; */ /* TODO - Remove Debugging */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-upcoming-match-info-date{
  width: 100%;
  height: 10vh;
  /* border: 1px solid green; */ /* TODO - Remove Debugging */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-upcoming-match-info-time{
  color: #e2e4e8 !important;
  font-weight: bold;
  padding-bottom: 1vh;
}
