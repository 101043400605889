
/* @media only screen and (min-width: 992px) {
  .match-card {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(80% - 20px);
  }
  .match-card-date {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(20% - 20px);
      border: 1px solid #7a7a7a;
      --background: #121212;
  }

  .match-card-and-date {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width:100%;
  }
}

ion-icon {
  pointer-events: none;
}

.match-card-and-date {

}

.match-card {
  padding-bottom: 2vh;
}

.match-card-date {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.match-card-date-header {
  background-color:#121212;
  border: 0.5px solid #7a7a7a;
  width: 100%;
}

.calendar-icon {
  margin: auto;
  font-size: 5vw;
  padding-top:10px;
  padding-bottom:10px;
}

.match-card-date-calendar-item{
  --background: #121212;
  text-align:center;
  width: 100%;
}

.match-card-date-calendar-item-detail {
  width:100%;
}

.match-time-badge{
  margin:auto;
  background-color: #121212;
  color:var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
}

.match-card-top-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width:100%;
}

.match-card-game-logo {
  width: calc(100% - 20px);
  margin:auto;
  margin-bottom:10px;
  margin-top:10px;
}

.match-card-vs-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: calc(100%);
  padding-top:3vh;
  padding-bottom:2vh;
  background: rgb(30,30,30);
  background: linear-gradient(90deg, rgba(30,30,30,1) 0%, rgba(61,133,198,1) 35%, rgba(30,30,30,1) 100%);
  margin-bottom: 2vh;
}

.match-card-vs-box > .match-box-player-1 > .player-1-prof-pic {
  margin-left: 10vw;
  height:8rem;
  width: 8rem;

  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #FFF;
  background-color: #FFF;
}

.match-card-vs-box > .match-box-player-1 > .player-1-prof-pic > .player-1-prof-pic-img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.match-card-vs-box > .vs-box-text {
  margin:auto;
  width: calc(30% - 20px);
  color: #FFF;
  font-size: 3rem;
}

.match-card-vs-box > .match-box-player-2 > .player-2-prof-pic {
  margin-right: 10vw;
  height:8rem;
  width: 8rem;

  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #FFF;
  background-color: #FFF;
}

.match-card-vs-box > .match-box-player-2 > .player-2-prof-pic > .player-2-prof-pic-img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.match-card-details {
  display: flex;
  flex-wrap: wrap;
  width:100%;
}

.match-card-details-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 2vw;
  width: calc(50% - 2vw);
}

.match-card-details-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 2vw;
  width: calc(50% - 2vw);
}

.match-card-buttons {
  margin-right: 2vw;
  margin-left: 2vw;
}

.match-card-button-cancel {
  color: #dedede;
}

.match-card-button-edit {
  color: #dedede;
}

.match-card-details-list-item {
  width: 100%;
}

.match-box-player-1-title {
  margin-left: 9.1vw;
  margin-bottom: 2vh;
  font-size: 1.25rem;
  color: #FFF;
  margin-top: -3vh;
  background-color: rgba(17,17,17, 0.75);
}

.match-box-player-2-title {
  margin-right: 7.5vw;
  margin-bottom: 2vh;
  font-size: 1.25rem;
  color: #FFF;
  margin-top: -3vh;
  background-color: rgba(17,17,17, 0.75);
}

.match-card-footer {
  margin-top: 2vh;
  margin-left:2vw;
  margin-right:2vw;
  border-top: solid 1px #171717;
  display:flex;
  justify-content: space-between;
  flex-direction:column;
  padding-top: 2vh;
}

.match-card-footer-propose-time-button{
  margin-top: 5vh;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54);
  color: #FFF;
}

.match-card-footer-propose-time-picker {
  margin-top:-20%;
}

.match-card-footer-proposed-time-text{
  font-size: 1rem;
  margin-top: 1vh;
}
.match-card-footer-accept-time-button {
  margin-top:2vh;
}

.time-porposed-toast {

}
*/

/** START NEW MATCH CARD **/
@media only screen and (min-width: 992px) {

  .match-card-bumper{
    width: 5%;
  }

  .match-card-player {
    width: 32.5%;

    /** border: 1px solid white; **/  /** View Scaffolding Boundaries**/
    /** height: 100%; **/
  }

  .match-card-center-details{
    width: 25%;
  }

  /** MATCH CARD PLAYER PROFILE **/
  .match-card-player-profile{
    width: 35%;

    top: 0;
    left: 0;
  }

  /** MATCH CARD PLAYER DETAILS **/
  .match-card-player-details{
    width: 65%;
    height: 100%;
  }

  .match-card-player-details-item{
    height: 16.25%;

    /**
    border: 1px solid red;
    **/  /** View Scaffolding Boundaries**/
  }

  .match-card-player-details-bumper {
    width: 100%;
    height: 15%;

    /**border: 1px solid red;**/  /** View Scaffolding Boundaries**/
  }

  .match-card-player-details-status > p{
    font-size: 1vw;
  }

  .match-card-player-details-username-text > p{
    font-size: 1.5vw;
  }

  .match-card-player-details-discord-text > p{
    font-size: 1vw;
  }

  .match-card-player-details-ign-text > p{
    font-size: 1vw;
  }

  /** MATCH CARD CENTER **/

  .match-card-center-details-score{
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
  }

  .match-card-center-details-game{
    height: 12%;
  }

  .match-card-center-details-type{
    height: 9%;
  }

  .match-card-center-details-rounds{
    height: 9%;
  }

  .match-card-center-details-score{
    height: 40%;
  }

  .match-card-center-details-score-vs-diamond {
    width: 3.5vw;
    height: 3.5vw;
  }

  .match-card-center-details-time {
    /*height: 30%;*/

  }

  .match-card-center-details-score-item{
    width: 33%;

    /** border: 1px solid yellow; **/  /** View Scaffolding Boundaries**/
  }

  .match-card-center-details-time-text > h1{
    font-size: 1.25vw;
  }

  .match-card-center-details-type-text > p {
    font-size: 1.25vw;
  }

  .match-card-center-details-rounds-text > p {
    font-size: 1vw;
  }

  .match-card-center-details-game-text {

  }

  .match-card-center-details-game-text > p {
    font-size: 1.75vw;
  }

  .match-card-center-details-vs-text{

  }

  .match-card-center-details-vs-text > p {
    font-size: 1.75vw;
  }

  .match-card-center-details-score-left > p {
    font-size: 5vw;
    margin-top: 35%;
  }

  .match-card-center-details-score-right > p {
    font-size: 5vw;
    margin-top: 35%;
  }

  .match-card-right-settings-button-icon{
  }

  .match-card-menu-container {
    width: 20%;
  }

  .match-card-menu-button-icon {
    margin-right: 5%;
    font-size: 0.9vw;
  }

  .match-card-menu-button-label{
    font-size: 0.9vw;
  }
}

@media only screen and (max-width: 991px) {

  .match-card{
    flex-wrap: wrap;
    height: 100%;
  }

  .match-card-player {
    padding-bottom: 2vh;
  }

  /** MATCH CARD PLAYER PROFILE **/
  .match-card-player-profile{
    width: 20%;
    top: 0;
  }

  .match-card-player-profile-left {
    left: 10%;
  }

  .match-card-player-profile-right {
    right: 10%;
  }

  /** MATCH CARD PLAYER DETAILS **/
  .match-card-player-details{
    width: 60%;
    height: fit-content;
  }

  .match-card-player-details-item{
    height: 16.25%;
    margin-top: 1vh;

    /**
    border: 1px solid red;
    **/  /** View Scaffolding Boundaries**/
  }

  .match-card-left-player{
    width: 90%;
    order: 3;
  }

  .match-card-right-bumper{
    order: 1;
    position: absolute;
    right: 2%;
  }

  .match-card-center-details{
    order: 2;
    width: 100%;
    margin-top: 1vh;
    margin-bottom: 2vh;
    padding-bottom: 2vh;
    border-bottom: 1px solid #FFFFFF;
  }

  .match-card-right-player{
    display:none !important;

    width: 100%;
    order: 4;
    border-top: 1px dashed #FFFFFF;
    padding-top: 2vh;
  }

  .match-card-left-bumper{
    order: 5;
    width: 10%;
  }

  .match-card-player-details-status > p{
    font-size: 5vw;
    line-height:normal;
  }

  .match-card-player-details-username-text > p{
    font-size: 6vw;
    line-height: normal;
  }

  .match-card-player-details-discord-text > p{
    font-size: 4vw;
    line-height: normal;
  }

  .match-card-player-details-ign-text > p{
    font-size: 4vw;
    line-height: normal;
  }

  .match-card-center-details-item {
    margin-bottom: 1vh;
  }

  .match-card-center-details-score{
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
  }

  .match-card-center-details-score-vs-diamond {
    width: 10vw;
    height: 10vw;
  }

  .match-card-center-details-time-text > h1{
    font-size: 4vw;
  }

  .match-card-center-details-type-text > p {
    font-size: 4vw;
  }

  .match-card-center-details-rounds-text > p {
    font-size: 3vw;
  }

  .match-card-center-details-game-text > p {
    font-size: 6vw;
  }

  .match-card-center-details-vs-text > p {
    font-size: 6vw;
  }

  .match-card-center-details-score-left > p {
    font-size: 10vw;
    margin-top: 35%;
  }

  .match-card-center-details-score-right > p {
    font-size: 10vw;
    margin-top: 35%;
  }

  .match-card-menu-container {
    width: fit-content;
  }

  .match-card-menu-button-icon {
    margin-right: 5%;
    font-size: 0.9vw;
  }

  .match-card-menu-button-label{
    font-size: 0.9vw;
  }

}

/** GENERAL CSS FOR ALL SCREEN SIZES **/
.matches-container{
  display:flex;
  justify-content:space-around;
  margin-top:5vh;
}

.match-card{
  /*background: linear-gradient(90deg, rgba(30,30,30,1) 0%, rgba(61,133,198,1) 35%, rgba(30,30,30,1) 100%);*/
  background-image: url(https://esporter.win/wp-content/uploads/2023/01/Banners-1-Outrun-Miami.png);
  background-size: cover;
  background-color: #171a1f;
  width: 95%;
  display: flex;
  justify-content: space-between;
  min-height: 25vh;
  border-radius: 10px;
  padding-top: 2vh;
  padding-bottom: 2vh;
  height: fit-content;
  position: relative;
  background-position: center center;
}

.match-card-background-image-overlay {
  background: rgb(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -2vh; /* Must be the same absolute value as .match-card's padding */
  margin-bottom: -2vh; /* Must be the same absolute value as .match-card's padding */
}

.match-card-bumper {
  /** border: 1px solid white; **/  /** View Scaffolding Boundaries**/
  height: 100%;
}

.match-card-player {
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.match-card-center-details{
  /** border: 1px solid white; **/  /** View Scaffolding Boundaries**/
  height: 100%;
  z-index: 2;

  background-color: rgba(23, 26, 31, 0.8);
  padding-top: 2vh;
  border-radius: 10px;
  padding-left: 1vw;
  padding-right: 1vw;
}

/** MATCH CARD PLAYER PROFILE **/
.match-card-player-profile{
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  position: relative;

  /** border: 1px solid blue; **/  /** View Scaffolding Boundaries**/
  height: 100%;
}

.match-card-player-profile-image {
  position:relative;
  top:0;
  left: 0;
}

.match-card-player-profile-image::part(image) {
  border-radius: 50%;
}

.match-card-player-profile-border {
  position:absolute;
  left: -12.5%;
  width: 125%;
}

/** MATCH CARD PLAYER DETAILS **/
.match-card-player-details{
  display: flex;
  justify-content: space-around;
  flex-flow: column;

  /** border: 1px solid blue; **/  /** View Scaffolding Boundaries**/
}

.match-card-player-details-left {
  align-items:flex-start;
  margin-left: 1vw;
}

.match-card-player-details-right {
  align-items:flex-end;
  margin-right: 1vw;
}

.match-card-player-details-status{
  display:flex;
  flex-flow: column;
  width: fit-content;
  border-radius: 5px;
  align-items:center;
  height: fit-content;
  margin-bottom: 1vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.match-card-player-details-status > p{
  color: #FFFFFF;
  font-weight: bold;
}

.match-card-player-details-status-success {
  background-color: var(--ion-color-primary);
}

.match-card-player-details-status-pending {
  background-color: var(--ion-color-medium);
}

.match-card-player-details-status-inProgress{
  background-color: var(--ion-color-success);
}


.match-card-player-details-status-danger{
  background-color: var(--ion-color-danger);
}

.match-card-player-details-status-clickable {
  cursor: pointer;
}

.match-card-player-details-badge{
  display:flex;
  width: fit-content;
  align-items: center;
  border-radius: 5px;
  background-color: #26292e;
  white-space: nowrap;
  cursor: pointer;
  /** border: 1px solid pink;**/  /** View Scaffolding Boundaries**/
}

.match-card-player-details-badge-left {
  padding-left: 0.25vw;
  padding-right: 0.5vw;
}

.match-card-player-details-badge-right {
  padding-right: 0.25vw;
  padding-left: 0.5vw;
}

.match-card-player-details-badge-icon{
  width: 20%;
  color: #9a9ca3;
  display:flex;
  align-items: center;
}

.match-card-player-details-badge-icon-left{
  margin-left: 0.25vw;
  margin-right: 0.5vw;
}

.match-card-player-details-badge-icon-right{
  margin-right: 0.25vw;
  margin-left: 0.5vw;
}

.match-card-player-details-badge-text{
  /** border: 1px solid pink; **/  /** View Scaffolding Boundaries**/
}


.match-card-player-details-username-text > p{
  color: #FFFFFF;
  font-weight: bold;
}

.match-card-player-details-text-left > p{
  text-align:left;
}

.match-card-player-details-text-right > p{
  text-align:right;
}

/** MATCH CARD CENTER **/
.match-card-center-details-item{
  width:100%;

  /** border: 1px solid green; **/  /** View Scaffolding Boundaries**/
}

.match-card-center-details-score-vs {
  display:flex;
  justify-content: space-around;
  flex-flow: column;
}

.match-card-center-details-time-text > h1{
  text-shadow: 2px 2px #17171750;
  margin-top: 5vh;
  color: #949494;
}

.match-card-center-details-type-text > p {
  color: #949494;
  text-shadow: 2px 2px #17171750;
  margin-bottom: 0.25rem;
}

.match-card-center-details-rounds-text > p {
  color: #FFFFFF;
  text-shadow: 2px 2px #17171750;
}

.match-card-center-details-game-text > p {
  color: #FFFFFF;
  text-shadow: 2px 2px #17171750;
  font-weight: bold;
  margin-bottom: 1vh;
}

.match-card-center-detauls-game-logo{
  height: 10vh;
}

.match-card-center-details-score-vs-diamond {
  margin: auto;
  transform: rotateY(0deg) rotate(45deg);
  border: 2px solid #3d85c6;
  background-color: #282b2f;
  display:flex;
  justify-content: center;
  align-content:center;
  flex-flow:column-reverse;
}

.match-card-center-details-vs-text > p {
  color: #FFFFFF;
  transform: rotateY(0deg) rotate(-45deg);
  font-family: 'Oswald', sans-serif;
  margin-top: 0.25vw;
  margin-left: 0.25vw;
}

.match-card-center-details-score-left > p {
  color: #FFFFFF;
  font-family: 'Oswald', sans-serif;
}

.match-card-center-details-score-right > p {
  color: #FFFFFF;
  font-family: 'Oswald', sans-serif;
}

.match-card-center-proposed-time-text > p {
  color: #FFFFFF;
  border: 1px solid rgba(255,255,255, 0.5);
  border-radius: 5px;
  margin-top: 1vh;
  margin-bottom: 1vh;
  background-color: rgba(255,255,255,0.1);
}

/** MATCH CARD RIGHT **/

.match-card-right-settings-button{
  --color: rgba(255, 255, 255, 0.8);
  margin-top: -10%;
}

/** MATCH CARD MENU **/
.match-card-menu-container{
  position: absolute;
  right: 0;
  border-radius: 5px;
  background-color: #292929;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 3;
}

.match-card-menu-button{
  --color: #FFFFFF;
}

.match-card-menu-button-icon {
  color: #FFFFFF;
}

.match-card-menu-button-icon-danger {
  color: var(--ion-color-danger) !important;
}

.match-card-menu-button-label{
  margin-right: auto;
}

.match-card-menu-button-label-danger{
  color: var(--ion-color-danger);
}

/** SCORES CONFLICT POPUP **/
.scores-conflict-popup .alert-wrapper {
  min-width: 50%;
}

.scores-conflict-popup .alert-head {
  text-align:center;
}

.scores-conflict-popup .alert-message {
  text-align:center;
}

.scores-conflict-popup .alert-button-group {
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

button.alert-button.alert-button-role-cancel{
  height: auto;
  min-height: 44px;
}

button.alert-button.alert-button-role-accept-opponent-score{
  background-color: var(--ion-color-primary);
  color: #FFFFFF;
  width: 25%;
  font-weight: normal !important;
  height: auto;
}

button.alert-button.alert-button-role-accept-opponent-score > span {
  justify-content: center;
  text-align:center;
}

button.alert-button.alert-button-role-edit-your-score{
  background-color: var(--ion-color-success);
  color: #FFFFFF;
  width: 25%;
  font-weight: normal !important;
  height: auto;
}

button.alert-button.alert-button-role-edit-your-score > span{
  justify-content: center;
  text-align:center;
}

button.alert-button.alert-button-role-reconfirm-your-score{
  background-color: var(--ion-color-medium);
  color: #FFFFFF;
  width: 25%;
  font-weight: normal !important;
  height: auto;
}

button.alert-button.alert-button-role-reconfirm-your-score > span {
  justify-content: center;
  text-align:center;
}

/** RESCHEDULE DATE TIME PICKER **/
.match-card-footer-propose-time-picker {
  margin-top:0%;
}

.match-card-footer-accept-time-button {
  margin: auto;
  width: 100%;
  margin-bottom: 10px !important; 
}
