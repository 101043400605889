.profile-header-info-details-name-ign {
  background-color: #26292e;
  display:flex;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #9a9ca3;
  cursor: pointer;
  align-items: center; 
}

.profile-header-info-details-name-ign-icon {
  width: 1vw;
  margin-right: 5px;
}
