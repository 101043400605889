.availability-container {
  top: 11%;
}

.container-explainer {
  margin-top: 2% !important;
  margin-bottom: 2% !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.availability-start {
  position: relative;
}

.availability-image {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:2%;
}

.availability-schedule-button {
  margin-right: 10%;
  margin-left: 10%;
}

.availability-image::part(image) {
  border-radius: 10px;
  opacity: 0.8;
}

.availability-emphasis {
  color: #FFFFFF !important;
}

.timezone-select {
  margin: auto;
  width: 80%;
  margin-bottom: 5%;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
}

.timezone-search {
  margin: auto;
  width: 80%;
  margin-top: 3%;
}

@media only screen and (min-width: 992px) {
  .availability-day-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.timezone-toast {

}

.timezone-search-select-container {
  margin-top: 5%;
}
