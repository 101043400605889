.leaderboards-container {
  top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboard-section{
  display:flex;
  justify-content:space-around;
  width: 100%;
}

.leaderboard-list{
  background-color: #181b20;
  margin-top:20px;
  width: 90%;
  border-radius: 10px;
  padding-top: 0px;
}

.leaderboard-item-first-place{
}

.leaderboard-item-first-place::part(native){
  background-color: #171717BB !important;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.leaderboard-item-ranks-2-3::part(native){
  background-color: #171717F0 !important;
  padding-bottom: 5px;
  min-width: 100%;
}

.leaderboard-item-ranks-4-plus::part(native){
  background-color: #171717F0 !important;
  padding-bottom: 5px;
  min-width: 100%;
}

.leaderboard-item{
  background-color: #181b20;
  border-bottom: 0.5px solid #7A7A7A;
  display:flex;
  justify-content: flex-start;
}

.leaderboard-item::part(native){
  background-color: #181b20;
}

.leaderboard-card-position{
  width: 15%;
  display:flex;
  justify-content:space-around;
  position:relative;
}

.leaderboard-card-rank-1-banner{
  position:absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.leaderboard-card-position-text-rank-1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leaderboard-card-position-text-ranks-2-3{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leaderboard-card-position-text-ranks-4-plus{

}

.leaderboard-card-position-text-rank-1 > h1{
  font-weight: bold;
  font-size: 2rem;
  text-shadow:3px 3px #171717;
}

.leaderboard-card-position-text-ranks-2-3 > h1{
  font-weight: bold;
  font-size: 1.5rem;
  text-shadow:2px 2px #171717;
}

.leaderboard-card-rank-1-image{

}

.leaderbaord-card-rank-2-3-image {
  width: 60%
}

/** LEADERBOARD CARD PLAYER PROFILE **/
.leaderboard-card-player-profile{
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  position: relative;

  width: 7%;
  height: 75px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.leaderboard-card-player-profile-image {
  position:relative;
  top:0;
  left: 0;
}

.leaderboard-card-player-profile-image::part(image) {
  border-radius: 50%;
}

.leaderboard-card-player-profile-border {
  position:absolute;
  left: -12.5%;
  width: 125%;
}
/** END LEADERBOARD CARD PLAYER PROFILE **/

.leaderboard-card-player-name {
  width: 20%;
}
.leaderboard-card-record{
  width: 40%;
  display:flex;
  justify-content: space-around;

}
