.profile-container {
  top: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 2.5%;
  margin-right: 2.5%;
  gap: 1vw;
}

.profile-header {
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  gap: 0px;
  min-height: 55vh;  /* TODO - remove debugging */
  /*max-height: 55vh; */ /* TODO - remove debugging */
  /*border: 1px solid red;*/ /* TODO - remove debugging */
  border-radius: 10px;
}

/** TODO - Combine classes from the banner and the picture-border sections because they are repetitive. Make these updates in the HTML too **/

/** START Banner **/

.profile-header-banner {
  min-height: 30vh;
  width: 100%;
  /*border: 1px solid green;*/ /* TODO - remove debugging */
  /* background-image: url(https://media.eventhubs.com/images/2019/09/11-nintendo-updates-official-banner-super-smash-bros-ultimate.jpg);*//* TODO - remove debugging */
  background-size: cover; /* TODO - remove debugging */
  border-radius: 10px 10px 0 0;
}

.profile-header-banner:hover {
  opacity: 0.75;
  cursor: pointer;
}

.profile-header-banner-edit-icon{
  display:none;
  color: #7e828b;
  font-size: 1.5vw;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 0.5vw;
  background-color: #26292e;
  border-radius: 5px;
}

.profile-header-banner:hover + .profile-header-banner-edit-icon{
  display:inline-block !important;
}

.profile-header-banner-edit-icon:hover {
  cursor: pointer;
}

.profile-header-banner-modal-title {
  text-align: center;
  font-size: 1.1rem;
}

.profile-header-banner-modal-confirm-button {
  /*--background: #3d85c6 !important;
  --color: #FFFFFF !important;*/
}

.profile-header-banner-modal-option-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-header-banner-modal-option-card {

}

.profile-header-banner-modal-option-card-wrapper {
  /* width: 45%; */
}

.profile-header-banner-modal-option-card-wrapper:hover {
  cursor: pointer;
}

.profile-header-banner-modal-option-card-selected {
  outline: 3px solid #3d85c6;
}

.profile-header-banner-modal-option-card-selected-icon-visible {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 2.5vw;
  color: #3d85c6;
}

.profile-header-banner-modal-option-card-selected-icon-hidden {
  display:none;
}

.profile-header-banner-modal-option-card-locked {
  outline: 3px solid #949494;
}

.profile-header-banner-modal-option-card-locked-icon-visible {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 2.5vw;
  margin-bottom: 3px;
}

.profile-header-banner-modal-option-card-locked-icon-hidden {
  display:none;
}

.profile-header-banner-modal-option-card-locked-overlay-visible {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  top: 0px;
}
/** END Banner **/

/** START Picture-Border **/

.profile-header-picture-border-modal-title{
  text-align: center;
  font-size: 1.1rem;
}

.profile-header-picture-border-modal-confirm-button {
  /*--background: #3d85c6 !important;
  --color: #FFFFFF !important;*/
}

.profile-header-picture-border-modal-menu {
  width: 100%;
  background-color: #0c0e12;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}

.profile-header-picture-border-modal-menu-item{

}

.profile-header-picture-border-modal-menu-item-selected{
  border-bottom: 1px solid #4182bb;
}

.profile-header-picture-border-modal-option-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.profile-header-picture-border-modal-option-card {

}

.profile-header-picture-border-modal-option-card-wrapper {
   width: 45%;
}

.profile-header-picture-border-modal-option-card-wrapper:hover {
  cursor: pointer;
}

.profile-header-picture-border-modal-option-card-selected {
  outline: 3px solid #3d85c6;
}

.profile-header-picture-border-modal-option-card-selected-icon-visible {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 2.5vw;
  color: #3d85c6;
}

.profile-header-picture-border-modal-option-card-selected-icon-hidden {
  display:none;
}

.profile-header-picture-border-modal-option-card-locked {
  outline: 3px solid #949494;
}

.profile-header-picture-border-modal-option-card-locked-icon-visible {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 2.5vw;
  margin-bottom: 3px;
}

.profile-header-picture-border-modal-option-card-locked-icon-hidden {
  display:none;
}

.profile-header-picture-border-modal-option-card-locked-overlay-visible {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  top: 0px;
}

/** END Picture-Border **/

.profile-header-info {
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #171a1f;
  padding-bottom: 2vh;
}

.profile-header-menu {
  width: 100%;
  /*border: 1px solid blue;*/ /* TODO - remove debugging */
  min-height: 5vh;
  background-color: #171a1f;
  border-top: 0.5px solid #222223;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 2vh;
  padding-top: 2vh;
  border-radius: 0 0 10px 10px;
}

.profile-header-profile-picture {
  width: 20%;
  z-index: 2;
}

.profile-header-profile-picture:hover {
  cursor: pointer;
}

.profile-header-profile-picture-edit-icon{
  display:none;
  color: #7e828b;
  font-size: 1.5vw;
  padding: 0.5vw;
  background-color: #26292e;
  position:absolute;
  left: 10vw;
  top: 40vh;
  z-index: 3;
  cursor: pointer;
  border-radius: 5px;

}

.profile-header-profile-picture:hover + .profile-header-profile-picture-edit-icon {
  display:inline-block !important;
}

.profile-header-profile-image {
  min-height: 11vw;
  min-width: 11vw;
  max-width: 11vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20%;
}

.profile-header-profile-border {
  min-height: 12vw;
  min-width: 12vw;
  max-width: 12vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-top: -75%;
}

.profile-header-profile-border::part(image){
  border-radius: 50%;
}

.profile-header-profile-image::part(image) {
  border-radius: 50%;
}

.profile-header-info-details {
  width: 80%;
  /*border: 1px solid blue;*/
  display: flex;
  flex-wrap: wrap;
}

.profile-header-info-details-name {
  width: 100%;
  /*border: 1px solid yellow;*/
  text-align:left;
}

.profile-header-info-details-name-text {
  color: #FFFFFF;
}

.profile-header-info-details-name-text-number {
  color: #7e828b;
}

.profile-header-info-details-name-subheader{
  color: #7e828b;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.profile-header-info-details-name-igns {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1vw;
}

.profile-header-info-details-name-ign {
  background-color: #26292e;
  display:flex;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #9a9ca3;
}

.profile-header-info-details-name-ign-icon {
  height: 2.5vh;
  margin-right: 5px;
}

.profile-header-menu-item {
  margin-left: 2vw;
}

.profile-header-menu-item-selected {
  border-bottom: 1px solid #4182bb;
}

.profile-header-menu-item-text {
  color: #e2e4e8 !important;
  font-weight: bold;
  font-size: 0.95rem !important;
}
