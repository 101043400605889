
@media only screen and (min-width: 992px) {
  .availability-day-card {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(50% - 20px);
  }
}



.times-selected > ion-item > ion-icon {
  color: var(--ion-color-primary);
}

.availability-time-section-heading {
  font-weight: bold;
  color: var(--ion-color-medium);
}

.availability-day-card-header {
  background-color: #0c0e12; 
}
