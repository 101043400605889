.splits-container {
  top: 15%;
  width: 90%;
  margin:auto;
}

.split-details{
  display:flex;
  justify-content: flex-start;
  flex-wrap:wrap;
  align-items: center
}

.split-details-logo{
  width: 35%;
}

.split-details-info {
  width: 60%;
  text-align: left;
  margin-left: 30px;
}

.split-details-info-top{
  display:flex;
  justify-content:space-between;
}

.split-details-name-group{
  width: 80%;
}

.split-details-label {
  font-size: 0.8rem !important;
  text-align:left;
  margin-top: 10px !important;
  color: #FFFFFF !important;
}

.split-details-input {
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  width: 90%;
  border-radius: 5px;
  text-align:left;
  padding-left: 10px !important;
}

.split-details-name-input{
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  border-radius: 5px;
  padding-left: 10px !important;
}

.split-details-info-active{
  display:flex;
  align-items: center;
}

.split-details-options {
  width: 100%;
  display:flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.split-details-option-container {
  width: 35%;
}

.split-details-input {
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  border-radius: 5px;
  width: 90%;
  padding-left: 10px !important;
}

.split-details-input-long{
  width: 55%;
  text-align:left;
}

.split-options{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
}

.split-options-card{
  width: 45%;
}

.split-options-card-title{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.split-options-player-id{
  font-size: 0.8rem !important;
}

.split-options-player-id:hover {
  cursor: pointer;
}

.split-options-split-name{
  font-size: 0.8rem !important;
}

.split-options-splits-active-button {
  color: #CACACA;
  --border-color: #CACACA;
}

.split-options-splits-delete-button{
  color: #ED6A5A;
}

.split-options-details-top-buttons-restore{
  color: #aabd8c;
}

.split-options-splits-add-button{
  font-size: 2rem;
  margin-right:0px !important;
}


.split-start-date-time-picker{
  margin-top:0%;
}
