.inbox-button {
  margin-right: 2vw;
  font-size: 1.25rem;
}

.inbox-button-icon-empty {
  color: #7a7a7a;
}

.inbox-button-icon-full {
  color: var(--ion-color-primary);
  z-index: 1; 
}

.inbox-button-badge {
  background-color: var(--ion-color-danger);
  font-size: 0.5rem;
  border-radius: 20px;
  margin-left: -1vw;
  margin-bottom: -1vh;
  z-index: 100;
}
