.login-background {
  --background: url('https://esporter.win/wp-content/uploads/2021/12/esporter-login-background.svg') no-repeat center center / cover;
}

.login-background-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-area {
  padding-top: 25vh;
  padding-left: 20vw;
  padding-right: 20vw;
}

.esporter-logo-login {
  margin-bottom: 5vh;
  margin-left: 7vw;
  margin-right: 7vw;
}

.sign-up-text {
  text-align: center;
  color: var(--ion-color-medium);
}
