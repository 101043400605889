.matches-container {
  top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.match-start {
  position: relative;
}

.match-image {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:2%;
}

.match-schedule-button {
  margin-right: 10%;
  margin-left: 10%;
}

.match-image::part(image) {
  border-radius: 10px;
  opacity: 0.8;
}

.match-emphasis {
  color: #FFFFFF !important;
}

@media only screen and (min-width: 992px) {
  .match-card-container {
    width:100%;
  }
}

.matches-navigation {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 0.75vw;
  width: 100%;
  margin-bottom: 1vh;
}

.matches-navigation-item {
  margin-left: 1vw;
  margin-right: 1vw;
}

.matches-navigation-selected {
  border-top: 1px solid white;
  width: 100%;
}

.matches-navigation-button {
  color: #ffffff;
}

.matches-matchmaking-header {
  width: 95%;
  border-radius: 10px;
  background-color: #171a1f;
  display: flex;
  flex-wrap: wrap;

}

.matches-matchmaking-title {
  width: 100%;
  text-align:left;
  padding: 2vw;
  margin-bottom: 0px;
  border-bottom: 0.5px solid #222223;
}

.matches-matchmaking-link {
  cursor:pointer;
}

.matches-matchmaking-help-text{
  margin-top:5vh !important;
}

.matches-matchmaking-split-options{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
}

.matches-matchmaking-split-option-card-link-wrapper{
  width: 30%;
  cursor: pointer;
}

.matches-matchmaking-split-option-card {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #171a1f;
}

.matches-matchmaking-split-option-card:hover {
  outline: 4px solid #428cff;
}


.matches-matchmaking-split-option-card-image {
  max-height: 200px;
  width: 50%;
  margin: auto;
  padding-top: 5vh;
}

.matches-matchmaking-split-option-card-header{

}


.matchmaking-refresh {
  font-size: 1 em;
  font-weight: bold;

}
