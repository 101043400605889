/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Potential Color Pallette Made Using Ionic Generator Here: https://ionicframework.com/docs/theming/color-generator **/
/*
:root {
	--ion-color-primary: #3d85c6;
	--ion-color-primary-rgb: 61,133,198;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #3675ae;
	--ion-color-primary-tint: #5091cc;

	--ion-color-secondary: #4676ae;
	--ion-color-secondary-rgb: 70,118,174;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #3e6899;
	--ion-color-secondary-tint: #5984b6;

	--ion-color-tertiary: #3e6899;
	--ion-color-tertiary-rgb: 62,104,153;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #375c87;
	--ion-color-tertiary-tint: #5177a3;

	--ion-color-success: #3EC300;
	--ion-color-success-rgb: 62,195,0;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #37ac00;
	--ion-color-success-tint: #51c91a;

	--ion-color-warning: #F0C808;
	--ion-color-warning-rgb: 240,200,8;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #d3b007;
	--ion-color-warning-tint: #f2ce21;

	--ion-color-danger: #ED6A5A;
	--ion-color-danger-rgb: 237,106,90;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #d15d4f;
	--ion-color-danger-tint: #ef796b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

}

*/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66,140,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  /** secondary **/
  --ion-color-secondary: #4676ae;
	--ion-color-secondary-rgb: 70,118,174;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #3e6899;
	--ion-color-secondary-tint: #5984b6;

  /** tertiary **/
  --ion-color-tertiary: #3e6899;
	--ion-color-tertiary-rgb: 62,104,153;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #375c87;
	--ion-color-tertiary-tint: #5177a3;

  /** success **/
  --ion-color-success: #3EC300;
	--ion-color-success-rgb: 62,195,0;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #37ac00;
	--ion-color-success-tint: #51c91a;

  /** warning **/
  --ion-color-warning: #F0C808;
	--ion-color-warning-rgb: 240,200,8;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #d3b007;
	--ion-color-warning-tint: #f2ce21;

  /** danger **/
  --ion-color-danger: #ED6A5A;
	--ion-color-danger-rgb: 237,106,90;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #d15d4f;
	--ion-color-danger-tint: #ef796b;

  /** dark **/
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #4676ae;
  	--ion-color-secondary-rgb: 70,118,174;
  	--ion-color-secondary-contrast: #ffffff;
  	--ion-color-secondary-contrast-rgb: 255,255,255;
  	--ion-color-secondary-shade: #3e6899;
  	--ion-color-secondary-tint: #5984b6;

    --ion-color-tertiary: #3e6899;
  	--ion-color-tertiary-rgb: 62,104,153;
  	--ion-color-tertiary-contrast: #ffffff;
  	--ion-color-tertiary-contrast-rgb: 255,255,255;
  	--ion-color-tertiary-shade: #375c87;
  	--ion-color-tertiary-tint: #5177a3;

    --ion-color-success: #3EC300;
  	--ion-color-success-rgb: 62,195,0;
  	--ion-color-success-contrast: #000000;
  	--ion-color-success-contrast-rgb: 0,0,0;
  	--ion-color-success-shade: #37ac00;
  	--ion-color-success-tint: #51c91a;

    --ion-color-warning: #F0C808;
  	--ion-color-warning-rgb: 240,200,8;
  	--ion-color-warning-contrast: #000000;
  	--ion-color-warning-contrast-rgb: 0,0,0;
  	--ion-color-warning-shade: #d3b007;
  	--ion-color-warning-tint: #f2ce21;

    --ion-color-danger: #ED6A5A;
  	--ion-color-danger-rgb: 237,106,90;
  	--ion-color-danger-contrast: #000000;
  	--ion-color-danger-contrast-rgb: 0,0,0;
  	--ion-color-danger-shade: #d15d4f;
  	--ion-color-danger-tint: #ef796b;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #0c0e12;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #0c0e12;

    --ion-toolbar-background: #0c0e12;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

@media (prefers-color-scheme: light) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #4676ae;
  	--ion-color-secondary-rgb: 70,118,174;
  	--ion-color-secondary-contrast: #ffffff;
  	--ion-color-secondary-contrast-rgb: 255,255,255;
  	--ion-color-secondary-shade: #3e6899;
  	--ion-color-secondary-tint: #5984b6;

    --ion-color-tertiary: #3e6899;
  	--ion-color-tertiary-rgb: 62,104,153;
  	--ion-color-tertiary-contrast: #ffffff;
  	--ion-color-tertiary-contrast-rgb: 255,255,255;
  	--ion-color-tertiary-shade: #375c87;
  	--ion-color-tertiary-tint: #5177a3;

    --ion-color-success: #3EC300;
  	--ion-color-success-rgb: 62,195,0;
  	--ion-color-success-contrast: #000000;
  	--ion-color-success-contrast-rgb: 0,0,0;
  	--ion-color-success-shade: #37ac00;
  	--ion-color-success-tint: #51c91a;

    --ion-color-warning: #F0C808;
  	--ion-color-warning-rgb: 240,200,8;
  	--ion-color-warning-contrast: #000000;
  	--ion-color-warning-contrast-rgb: 0,0,0;
  	--ion-color-warning-shade: #d3b007;
  	--ion-color-warning-tint: #f2ce21;

    --ion-color-danger: #ED6A5A;
  	--ion-color-danger-rgb: 237,106,90;
  	--ion-color-danger-contrast: #000000;
  	--ion-color-danger-contrast-rgb: 0,0,0;
  	--ion-color-danger-shade: #d15d4f;
  	--ion-color-danger-tint: #ef796b;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #0c0e12;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #0c0e12;

    --ion-toolbar-background: #0c0e12;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
