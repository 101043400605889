.leaderboards-search-filter-container {
  transform: translateY(-50%);
  top: 50%;
}

.leaderboard-search-filter {
  min-height: 20px;
  width: 90%;
  border-radius: 10px;
  background-color: #171a1f;
  padding-top: 2vh;
  padding-bottom: 1.5vh;
  padding-left: 2vw;
  padding-right: 2vw;
  display:flex;
  justify-content:space-around;
  align-items:center;
}

.leaderboard-split-select{
  outline: 0.25px solid #12151a;
  border-radius: 5px;
  width: 30%;
  --background: #12151a;
}

.leaderboard-split-select::part(placeholder),
.leaderboard-split-select::part(text) {
  padding-left:20px;
}
