.games-container {
  top: 15%;
  width: 90%;
  margin:auto;
}



.game-cards-container {
  display: flex;
  justify-content: space-around;
  column-gap: 5px;
  flex-wrap: wrap;
}

.game-details{
  display:flex;
  justify-content: flex-start;
  flex-wrap:wrap;
  align-items: center
}

.game-details-info-top{
  display:flex;
  justify-content:space-between;
}

.game-details-info-name {
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  margin-right: 20px;
  border-radius: 5px;
}

.video-details-logo-url {
  width: 50%;
}

.game-details-name {
  width: 85%;
}

.game-details-icon-url {
  width: 55%;
}

.video-details-logo-url{
  text-align:left;
}

.game-details-icon-url{
  text-align:left;
}

.video-details-logo-url-input {
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  width: 90%;
  border-radius: 5px;
  padding-left: 10px !important;
}

.game-details-name-input {
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  width: 90%;
  border-radius: 5px;
}


.game-details-icon-url-input {
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  width: 90%;
  border-radius: 5px;
}


.game-details-logo-url-label {
  font-size: 0.8rem !important;
  text-align:left;
  margin-top: 10px !important;
  color: #FFFFFF !important;
}

.game-details-icon-url-label {
  font-size: 0.8rem !important;
  text-align:left;
  margin-top: 10px !important;
  color: #FFFFFF !important;
}

.game-details-name-label {
  font-size: 0.8rem !important;
  text-align:left;
  margin-top: 10px !important;
  color: #FFFFFF !important;
}

.game-details-logo{
  width: 35%;
}

.game-details-icon {
  width: 10%;
}

.game-details-icon::part(image){
  max-height: 50px;
}

.game-details-logo-icon-options {
  width: 100%;
  display:flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.game-details-info {
  width: 60%;
  text-align: left;
  margin-left: 30px;
}

.game-details-info-active{
  display:flex;
  align-items: center;
  width: 30%;
}

.video-details-toggle {
  padding-right: 1vw;
  padding-left: 1vw;
}

.video-details-label {
  font-size: 0.8rem !important;
  text-align:left;
  margin-top: 10px !important;
  color: #FFFFFF !important;
}

.video-details-toggles-container {
  display: flex;
  margin-top: 10px;
}

.video-details-option-container {
  width: 50%;
}

.video-details-input-textarea{
  font-size: 1.3rem;
  outline: 0.5px solid #7A7A7A;
  width: 90%;
  border-radius: 5px;
  text-align:left;
  padding-left: 10px !important;
}

.game-options{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
}

.video-options-card{
  width: 100%;
}

.game-options-split-name{
  font-size: 0.8rem !important;
}

.game-options-splits-active-button {
  color: #CACACA;
  --border-color: #CACACA;
}

.game-options-splits-delete-button{
  color: #ED6A5A;
}

.game-options-splits-add-button{
  font-size: 2rem;
  margin-right:0px !important;
}
