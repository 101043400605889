.users-container {
  top: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.user-card-container {
  width: 95%;
}

.user-card{
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin:auto;
}

.user-card::part(native) {
  background-color: #181b20;
}

.user-card-list {
  background-color: #181b20;
  border-radius: 10px;
}

.user-card-container-total-users-text{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.user-card-bumper{
  width: 2%;
  background-color: #181b20;
}

/** USER CARD PLAYER PROFILE **/
.user-card-player-profile{
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  position: relative;

  width: 5%;
  height: 75px;
}

.user-card-player-profile-image {
  position:relative;
  top:0;
  left: 0;
}

.user-card-player-profile-image::part(image) {
  border-radius: 50%;
}

.user-card-player-profile-border {
  position:absolute;
  left: -12.5%;
  width: 125%;
}
/** END USER CARD PLAYER PROFILE **/

.user-card-name{
  width: 30%;
  margin-left: 2vw;
  display:flex;
  flex-flow: column;
}

.user-card-name-display{
  font-weight: bold;
  font-size: 1.2rem;
}
.user-card-name-id{
  margin-top:1vh;
  font-size: 0.8rem;
}

.user-card-details{
  display: flex;
  flex-flow: column;
  width: 65%;
}

.user-card-details-top{
  width: 100%;
  display:flex;
  justify-content: space-around;
  min-height: 80%;
  padding-bottom: 2vh;
}

.user-card-details-top-contact{
  display:flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 75%;
}

.user-card-details-top-contact-icon {
  margin-right: 1vw;
}

.user-card-details-top-contact-text{
  font-size: 1rem;
  font-weight: bold;
}

.user-card-details-top-buttons {
  display:flex;
  justify-content: flex-end;
  width: 25%;
}

.user-card-details-top-buttons-delete{
  color: #ED6A5A;
}

.user-card-details-top-buttons-restore{
  color: #aabd8c;
}

.user-card-details-info{
  display:flex;
  justify-content: space-between;
  min-height: 20%;
}

.user-card-details-info-trial{
  width: 33%;
}

.user-card-details-info-registered{
  width: 33%;
}

.user-card-details-info-login{
  width: 33%;
}

.archive-user-popover::part(content){
 width: 40%;
 text-align:center;
}

.archive-user-popover-container{
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.archive-user-buttons{
  display:flex;
  justify-content: flex-end;
}
