.matchmaking-create-match-recommended-times {
  --width: 50vw;
}

.matchmaking-create-match-recommended-times > div {
  display:flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2vw;
}

.matchmaking-create-match-recommended-times-title{
  width: 100%;
  text-align: center;
  color: #8b8b8b;
}

.matchmaking-create-match-recommended-time-card {
  width: 30%;
}

.matchmaking-create-match-recommended-time-card:hover {
  outline: 4px solid #428cff;
  cursor: pointer;
}

.matchmaking-create-match-recommended-times-refresh-button {
  --background: #8b8b8b;
}


@media only screen and (min-width: 992px) {
  .matchmaking-card-challenge-button {
    font-size: 1.25vw;
  }

  .match-card-in-progress-scoreboard-input {
    font-size: 2vw;
  }

  .match-card-in-progress-submit {
    font-size: 1.25vw;
  }

  .match-card-center-details-score-label{
    display:none;
  }

  .matchmaking-find-match-time-picker {
    margin-top:-20%;
  }
}

@media only screen and (max-width: 991px) {
  .matchmaking-card-challenge-button {
    font-size: 5vw;
  }

  .match-card-in-progress-scoreboard-input {
    font-size: 5vw;
    width: 75%;
    margin: auto;
  }

  .match-card-in-progress-submit {
    font-size: 4vw;
  }

  .match-card-center-details-score-label{
    margin-top: -22px !important;
    font-size: 3vw !important;
  }

  .matchmaking-find-match-time-picker {
    margin-top:-35%;
  }
}

.match-card-details {
  display: flex;
  flex-wrap: wrap;
  width:100%;
}

.match-card-details-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 2vw;
  width: calc(50% - 2vw);
}

.match-card-details-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 2vw;
  width: calc(50% - 2vw);
}

.match-card-buttons {
  margin-right: 2vw;
  margin-left: 2vw;
}

.match-card-button-cancel {
  color: #dedede;
}

.match-card-button-edit {
  color: #dedede;
}

.match-card-details-list-item {
  width: 100%;
}

.matchmaking-card-challenge-button {
  height: 10vh;
  width: 75%;
  padding-top: 3vh;
  --background: rgba(40, 40, 51, 0.85);
  --border-color: #3d85c6;
  --border-width: 1px;
  --border-style: solid;
}

/* CHALLENGE */
.matchmaking-card-panel{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.matchmaking-card-panel-challenge-counter{
  width: 10%;
}

.matchmaking-card-panel-challenge-counter-image{
  max-width: 2vw;
}

.matchmaking-card-panel-challenge-text{
  width: fit-content;
}

/* AWAITING */

.match-card-awaiting-details {
  width: 100%;
}

.match-card-awaiting-cancel-button {
  color: #7a7a7a;
}


/* IN PROGRESS */
.match-card-in-progress {
  width: 100%;
}

.match-card-in-progress-message {
  margin-top: 0;
  margin-bottom: 5%;
  font-size: 1.25rem;
  color: #FFFFFF;
}

.match-card-in-progress-scoreboard {
  display: flex;
  justify-content: space-between;
}

.match-card-in-progress-scoreboard-player-1 {
}

.match-card-in-progress-scoreboard-player-2 {

}

.match-card-in-progress-scoreboard-input {
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}

.match-card-in-progress-scoreboard-winner {
}

.match-card-in-progress-scoreboard-winner-radio-group {
  display:flex;
  justify-content: space-around;
}

.match-card-in-progress-scoreboard-winner-radio-group-item {
  color: #cacaca;
}

.match-card-in-progress-scoreboard-winner-radio-group-radio{
  margin-right: 5px;

}

.match-card-in-progress-scoreboard-winner-radio-group-item-label {
}

.match-card-in-progress-buttons {
  display:flex;
  justify-content: space-between;
}

.match-card-in-progress-cancel {
  color: var(--ion-color-danger);
}

.match-card-in-progress-submit {
  width: 75%;
  margin-top: 3vh;
  --background: rgba(40, 40, 51, 0.85);
  --border-color: #3d85c6;
  --border-width: 1px;
  --border-style: solid;
}

.match-card-panel-done {
  border: 1px solid #FFFFFF;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
}

.match-card-panel-done-title {
  text-align:center;
  font-size: 1.25rem;
}

.match-card-panel-done-conceded-text {
  color: var(--ion-color-danger) !important;
}

.popup {

}

/* CSS Animation for AWAITING Panel */
.animation-container{
  padding:0;
  margin-left: auto;
  margin-right: auto;
  width:100%;height:100%;
  align-items:center;
  display: flex;
  justify-content:center;
}

.animation-container div{
  position:absolute;
  width:90px;height:51px;
}

.animation-container div:nth-of-type(2){transform:rotate(60deg)}
.animation-container div:nth-of-type(3){transform:rotate(-60deg)}
.animation-container{transform:rotate(30deg)}


.animation-container div div{width:100%;height:100%;position:relative}

.animation-container div div span{
  position:absolute;
  width:4px;height:0%;
  background:#3d85c6;z-index:999999;
}

.animation-container .h1{left:0;
  animation: load1 7.2s ease infinite;
}
.animation-container .h2{right:0;
  animation: load2 7.2s ease .6s infinite;
}
.animation-container .h3{right:0;
  animation: load3 7.2s ease 1.2s infinite;
}
.animation-container .h4{right:0;
  animation: load4 7.2s ease 1.8s infinite;
}
.animation-container .h5{left:0;
  animation: load5 7.2s ease 2.4s infinite;
}
.animation-container .h6{left:0;
  animation: load6 7.2s ease 3s infinite;
}

@keyframes load1 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.944444433%{top:0;height:0}
/*   91.6666667%{top:0;height:0%;} */
}

@keyframes load2 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.944444433%{bottom:0;height:0}
/*   91.6666667%{bottom:0;height:0%} */
}

@keyframes load3 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.94444443%{bottom:0;height:0}
/*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load4 {
  0%{top:0;height:0}
  6.944444444%{top:0;height:100%}
  50%{bottom:0;height:100%}
  59.94444443%{bottom:0;height:0}
/*   91.6666667%{bottom:0;height:0%;} */
}

@keyframes load5 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.94444443%{top:0;height:0}
/*   91.6666667%{top:0;height:0%;} */
}

@keyframes load6 {
  0%{bottom:0;height:0}
  6.944444444%{bottom:0;height:100%}
  50%{top:0;height:100%}
  59.94444443%{top:0;height:0}
/*   91.6666667%{top:0;height:0%;} */
}
