.events-container {
  top: 10%;
}

.events-calendar {
  margin-right: 5vw;
  margin-left: 5vw;
}

.gcal-event {
  white-space:initial;
  color: #f4f5f8;
  background-color: #4676ae;
  margin-bottom: 2px;
}

.fc-day-today {
  background-color: #2C3E50  !important;
}

.fc .fc-col-header-cell-cushion {
  color: #FFFFFF;
}

.fc .fc-daygrid-day-number {
  color: #f4f5f8;
}

.fc .fc-view-harness {
  height: 100vh !important; 
}
