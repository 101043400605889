.game-card-container {
  width: 30%;
}

.game-card-image{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: 90%;
}

.game-card-subtitle {
  display:flex;
  justify-content:center;
  align-items: center;
}

.game-card-subtitle-toggle{
  padding-left: 1vw; 
}

.game-card-subtitle-split-id{
  font-size: 0.8rem;
}

.game-card-content-container{
  height: 50px;
}

.game-card-content{
  display:flex;
  justify-content: flex-end;
  align-items: flex-end;

  position: absolute;
  bottom: 0px;
  right: 0px;
}

.game-card-button{
  --color: #CACACA;
}

.game-card-icon{
  color: #CACACA;
}
