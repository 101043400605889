.profile-left-container {
  width: 49%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vw;
}

.profile-right-container {
  width: 49%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vw;
}

.profile-section-title {
  background-color: #181b20;
  border-radius: 10px 10px 0 0;
  min-height: 7.5vh; /* TODO - remove debugging */
  padding-left: 1.5vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
  border-bottom: 0.5px solid #222223;
  width: 100%;
}

.profile-section-title-text {
  color: #e2e4e8 !important;
  font-weight: bold;
  font-size: 1rem !important;
}

.profile-section-body {
  background-color: #171a1f;
  border-radius: 0 0 10px 10px;
  min-height: 20vh;
  width: 100%;
  padding-bottom: 3vh;
  padding-top: 3vh;
}

.profile-section-body-text {
  padding-left: 1.5vw;
}

.profile-about {
  width: 100%;
  border-radius: 10px;
  text-align: left;

}

.profile-games {
  width: 100%;
  border-radius: 10px;
  text-align: left;
}

.profile-games-list{
  width: 90%;
  border-radius: 10px;
  background-color: #12151a;
  margin-left: 1.5vw;
}

.profile-game-image {
  width: 25%;
  max-height:200px;
}

.profile-upcoming-matches {
  width: 100%;
  /* min-height: 40vh; */ /* TODO - remove debugging */
  /* max-height: 40vh; */ /* TODO - remove debugging */
  /* border: 1px solid red; */ /* TODO - remove debugging */
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3vh;
}

.profile-upcoming-matches-number-of-matches-text {
  color: #7e828b !important;
  margin-left: 1vw;
}

.profile-upcoming-matches-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 2vh;
}

.profile-upcoming-match {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  /* border: 1px solid red; */ /* TODO - remove debugging */
  border-radius: 5px;
  background-color: #12151a;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border-radius: 10px;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-bottom: 1vh;
}

.profile-upcoming-match-image {
  width: 25%;
}

.profile-upcoming-match-info{
  width: 75%;
  /* border: 1px solid blue;*/ /* TODO - Remove Debugging */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-upcoming-match-info-details{
  width: 50%;
  /* border: 1px solid yellow; */ /* TODO - Remove Debugging */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-upcoming-match-info-details-game-type{
  color: #e2e4e8 !important;
  font-weight: bold;
  padding-bottom: 1vh;
}

.profile-upcoming-match-info-date-time{
  width: 50%;
  /* border: 1px solid yellow; */ /* TODO - Remove Debugging */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-upcoming-match-info-date{
  width: 100%;
  height: 10vh;
  /* border: 1px solid green; */ /* TODO - Remove Debugging */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-upcoming-match-info-time{
  color: #e2e4e8 !important;
  font-weight: bold;
  padding-bottom: 1vh;
}

.profile-upcoming-matches-more {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-upcoming-matches-more-button {
  width: 90%;
  --background: #282a2f;
  --box-shadow: 0;
}

.profile-settings-section-body {
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-settings-user-info {
  text-align: left;
  width: 100%;
}

.profile-settings-user-info-list{
  width: 90%;
  border-radius: 10px;
  background-color: #12151a;
}

.profile-settings-user-info-list-item{
  --background: #12151a;
}

.profile-settings-user-info-list-item-label{
  font-weight: bold;
  display:flex !important;
}

.profile-settings-user-info-list-item-label-data{
  text-align:right;
  --color: #7e828b !important;
}

.profile-settings-user-info-list-item-input{
  text-align: right;
}

.profile-settings-user-info-item-label-icon{
  height: 3vh;
  margin-right: 10px;
  display:inline-block;
}

.profile-settings-save {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-settings-save-button {
  width: 90%;
  --background: #282a2f;
  --box-shadow: 0;
}

.profile-badges {
  width: 100%;
  min-height: 20vh; /* TODO - remove debugging */
  max-height: 20vh; /* TODO - remove debugging */
  /* border: 1px solid red; */ /* TODO - remove debugging */
  border-radius: 10px;
}

.profile-skills {
  width: 100%;
  min-height: 20vh; /* TODO - remove debugging */
  max-height: 20vh; /* TODO - remove debugging */
  /* border: 1px solid red; */ /* TODO - remove debugging */
  border-radius: 10px;
}

.profile-achievements {
  width: 100%;
  min-height: 40vh; /* TODO - remove debugging */
  max-height: 40vh; /* TODO - remove debugging */
  /* border: 1px solid red; */ /* TODO - remove debugging */
  border-radius: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .profile-left-container {
    width: 100%;
    padding-left:5px;
  }
  .profile-right-container {
    width: 100%;
    padding-left:5px; 
  }

}
