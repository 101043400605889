.users-search-filter {
  min-height: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: #171a1f;
  padding-top: 2vh;
  padding-bottom: 1.5vh;
  padding-left: 2vw;
  padding-right: 2vw;
  display:flex;
  justify-content:space-around;
  align-items:center;
}

.matchmaking-search-filter-game-title {
  margin-left: 2vw;
  margin-bottom: auto;
  margin-top: auto;
}

.games-select {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  margin-top: 0.5vh;
}

.games-search-select-container {
  width: 25%;
}

.matchmaking-gamesFilterIcon {

}

#matchmaking-filter-button {
  margin-left: 0.5vw;
  margin-right: 1vw;
}

#matchmaking-sort-button {
  margin-left: 0.5vw;
  margin-right: 1vw;
}

.matchmaking-search {
  margin-left: 0.5vw;
  margin-right: 1vw;
  --background: #12151a;
  --border-radius: 5px;
}

.matchmaking-search > .searchbar-input-container{
  margin-top: -1vh;
}

.games-select {
  display:none;
}
