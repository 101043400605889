
.notifications-container {
  position: absolute;
  max-width: 40%;
  right: 0;
  border: 1px solid #7a7a7a;
  max-height: 300px;
  overflow-y: scroll;
}

.notification-item{
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.notification-item-label{
  font-weight: bold;
}

.notification-item-description{
  color: #7a7a7a;
}

.notification-item-img {
  max-width: 20%;
  padding-right: 5px;
}

.notification-item-icon {
  width: 50%;
  font-size: 3rem;
  margin-right: 1vw;
  color: #3d85c6; 
}

.notification-item-button {
  color: #7a7a7a;

}
