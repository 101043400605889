.recordings-container {
  top: 40px;
}

.recordings-navigation {
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 0.75vw;
  width: 100%;
  margin-bottom: 1vh;
}

.recordings-navigation-item {
  margin-left: 1vw;
  margin-right: 1vw;
}

.recordings-navigation-selected {
  border-top: 1px solid white;
  width: 100%;
}

.recordings-navigation-button {
  color: #ffffff;
}

.recording-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 2vw;
}

.recordings-title {
  text-align:left;
  width: 100%;
  padding-left: 2vw;
}

.recording-card{
  width: 30%;
}

.recording-card:hover {
  cursor: pointer;
}

.recordings-iframe-container {
  padding:56.25% 0 0 0;
  position:relative;
  margin-bottom: 10vh;
}

.recordings-video-iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
